import { proxy, subscribe } from 'valtio';

import SettingsStore from './SettingsStore';
import axios from 'axios';
import { Address, Chain } from 'viem';
import { getBalances } from '@/hooks/Vault/getTokenBalances';
import { getPaymasterConfigs } from './api/paymaster/getPaymasterAddresses';
import { getImportedAssetsFromLocalStorage } from '@/helpers/Vault/getImportedAssetsFromLocalStorage';

export interface TAsset {
    name: string;
    address: Address;
    symbol: string;
    decimals: number;
    chainId: number;
    logoURI?: string;
    precision?: number;
    extensions?: {
        bridgeInfo: {
            [key: string]: { tokenAddress: string };
        };
        allow_locking?: boolean;
        coingecko_api_id?: boolean;
    };
}

export interface TBalance {
    symbol: string;
    balance: string;
}

export interface IAssetsState {
    assets: TAsset[] | null;
    balances: TBalance[] | null;
    assetsLoading: boolean;
    balancesLoading: boolean;
}

const state = proxy<IAssetsState>({
    assets: [] as TAsset[] | null,
    balances: [] as TBalance[] | null,
    assetsLoading: false,
    balancesLoading: false,
});

const AssetsStore = {
    state,
    setAssets(assets: TAsset[] | null) {
        state.assets = assets;
        state.assetsLoading = false;
    },
    setAssetsLoading(loading: boolean) {
        state.assetsLoading = loading;
    },
    setBalances(balances: TBalance[] | null) {
        state.balances = balances;
        state.balancesLoading = false;
    },
    setBalancesLoading(loading: boolean) {
        state.balancesLoading = loading;
    },
};

export const fetchAssets = async () => {
    const assetsUrl = process.env.NEXT_PUBLIC_ASSETS_URL?.replace(
        '{chain_id}',
        String(SettingsStore.state.activeChain?.id ?? ''),
    );

    if (!assetsUrl) {
        return;
    }

    AssetsStore.setAssetsLoading(true);
    let assets: TAsset[] = [];

    try {
        const response = await axios(assetsUrl);

        assets = response.data.tokens || [];
    } catch (error) {
        console.error('Error fetching assets:', error);
        AssetsStore.setAssetsLoading(false);
    }

    // Filter local tokens for the current chain
    const localAssets = getImportedAssetsFromLocalStorage();
    const chainId = SettingsStore.state.activeChain?.id.toString() || '';
    const localChainTokens = localAssets[chainId] ? localAssets[chainId] : [];

    // Create a map to ensure unique tokens based on address
    const tokenMap = new Map();

    assets.forEach((token: TAsset) => tokenMap.set(token.address, token));
    localChainTokens.forEach((token: TAsset) => tokenMap.set(token.address, token));

    // Convert map values back to an array to set in the store
    const combinedTokens = Array.from(tokenMap.values());

    AssetsStore.setAssets(combinedTokens);
};

export const fetchBalances = async (accountAddress?: string, activeChain?: Chain) => {
    if (!AssetsStore.state.assets || !accountAddress || !activeChain) {
        AssetsStore.setBalances([]);

        return;
    }

    AssetsStore.setBalancesLoading(true);

    try {
        const newBalances = await getBalances(AssetsStore.state.assets, accountAddress, activeChain);

        AssetsStore.setBalances(newBalances);
    } catch (error) {
        console.error('Error fetching balances:', error);
    } finally {
        AssetsStore.setBalancesLoading(false);
    }
};

let prevActiveChain = SettingsStore.state.activeChain;

subscribe(SettingsStore.state, () => {
    if (AssetsStore.state.assets?.length === 0) {
        fetchAssets();
        getPaymasterConfigs();
    } else if (SettingsStore.state.activeChain !== prevActiveChain) {
        prevActiveChain = SettingsStore.state.activeChain;
        fetchAssets();
        getPaymasterConfigs();
    }
});

export default AssetsStore;
