import { proxy } from 'valtio';

export interface TUser {
    id?: number;
    email?: string;
    is_funded?: boolean;
    smart_wallet_address: string;
    privy_wallet_address?: string;
    external_wallet_address?: string;
    google_email?: string;
    discord_username?: string | null;
    discord_id?: string | null;
    telegram_id?: string | null;
    initial_refs?: [];
    referral_code?: string;
    referred_by?: string;
    created_at?: Date;
}

export interface IUserState {
    user: TUser;
    userIsSet: boolean;
    userPosition: number | string;
    userTVL: number | string;
    userTvlLoading: boolean;
    referredCount: number;
}

const state = proxy<IUserState>({
    user: {
        smart_wallet_address: '',
    },
    userIsSet: false,
    userTvlLoading: false,
    userPosition: 0,
    userTVL: 0,
    referredCount: 0,
});

const UserStore = {
    state,
    setUser(payload: TUser) {
        state.user = {
            ...state.user,
            ...payload,
        };
    },

    setUserIsSet(payload: boolean) {
        state.userIsSet = payload;
    },

    setUserRank(payload: number | string) {
        state.userPosition = payload;
    },

    setUserTVL(payload: number | string) {
        state.userTVL = payload;
        state.userTvlLoading = false;
    },

    setUserTvlLoading(payload: boolean) {
        state.userTvlLoading = payload;
    },

    setReferredCount(payload: number) {
        state.referredCount = payload;
    },

    resetUser() {
        state.user = {
            smart_wallet_address: '',
        };
        state.userIsSet = false;
        state.userPosition = 0;
        state.referredCount = 0;
        state.userTVL = 0;
        state.referredCount = 0;
    },
};

export default UserStore;
